<template>
  <div class="examineDetail">
    <div class="top">
      <div class="topBar">
        <div class="titleBox">
          <div
            class="back"
            @click='back'
          >
            <div class="iconfont iconlfArrow"></div>
            <div
              class="text"
              @click="back"
            >返回</div>
          </div>
          <div class="title">
            <div class="titleText">{{state.examineDetail.relCase.caseName}}</div>
            <div class="titleText">{{state.examineDetail.applyTypeName}}</div>
            <div class='titleSelect'>{{state.examineDetail.statusName}}
            </div>
          </div>
          <!-- <div class="bottom"> -->
          <el-button
            type="primary"
            plain
            size='primary'
            @click="handleRefuse"
            v-if="btnShow"
          >拒绝</el-button>

          <el-button
            type="primary"
            size='primary'
            v-if="btnShow"
            @click="handlePass"
          >同意</el-button>
          <!-- </div> -->

        </div>
      </div>
    </div>
    <div class="content">
      <examine-detail-content
        :examineType="state.examineDetail.applyType"
        :examineDetail="state.examineDetail"
      ></examine-detail-content>
    </div>
    <my-dialog
      :isShow="isShow"
      :title="title"
      :closeDialog="handleCloseTypeDialog"
    >
      <div class="applySeal">
        <el-input
          type='textarea'
          v-model="remark"
        ></el-input>
        <div class="formBottomBtn">
          <el-button
            type='primary'
            plain
            size='small'
            @click='cancelHandle'
          >取消</el-button>
          <el-button
            type='primary'
            size='small'
            @click='submitHandle'
          >确定</el-button>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, onMounted, toRef } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getExamineDetail, passExamine, refuseExamine } from "@/api/examine";
import MyDialog from "../components/myDialog.vue";
import ExamineDetailContent from "@/components/ExamineDetailContent";
export default defineComponent({
  name: "ExamineDetails",
  components: { ExamineDetailContent, MyDialog },
  setup(props, context) {
    const router = useRouter();
    const currentRoute = useRoute();
    const applyId = currentRoute.query.applyId;
    const backPath = currentRoute.query.content;
    const btnShow = currentRoute.query.content == "myExamine";
    const isShow = ref(false);
    const title = ref("");
    const remark = ref("");
    const state = reactive({
      examineDetail: {
        applyAudit: {
          invoiceNo: "",
          seqNo: "",
        },
        applyFileList: [
          {
            changeDate: "",
            changeUser: {
              accountNo: "",
              birthday: "",
              bizTypeGoodat: "",
              changeDate: "",
              changeUser: {
                accountNo: "",
                birthday: "",
                bizTypeGoodat: "",
                changeDate: "",
                changeUser: {},
                changeUserId: 0,
                createDate: "",
                createUser: {},
                createUserId: 0,
                deleteDate: "",
                deleteUserId: 0,
                email: "",
                firstPracticeDate: "",
                headPortraitUrl: "",
                isDelete: 0,
                licenseNo: "",
                locationCityName: "",
                locationProvinceName: "",
                locationTownName: "",
                officeName: "",
                password: "",
                remark: "",
                sex: 0,
                tel: "",
                userId: 0,
                userName: "",
                version: 0,
                wxHeadImg: "",
                wxNickName: "",
                wxOpenId: "",
              },
              changeUserId: 0,
              createDate: "",
              createUser: {},
              createUserId: 0,
              deleteDate: "",
              deleteUserId: 0,
              email: "",
              firstPracticeDate: "",
              headPortraitUrl: "",
              isDelete: 0,
              licenseNo: "",
              locationCityName: "",
              locationProvinceName: "",
              locationTownName: "",
              officeName: "",
              password: "",
              remark: "",
              sex: 0,
              tel: "",
              userId: 0,
              userName: "",
              version: 0,
              wxHeadImg: "",
              wxNickName: "",
              wxOpenId: "",
            },
            changeUserId: 0,
            createDate: "",
            createUser: {},
            createUserId: 0,
            deleteDate: "",
            deleteUserId: 0,
            fileId: 0,
            fileSize: 0,
            isDelete: 0,
            originalFileName: "",
            ossPath: "",
            ossUrl: "",
            remark: "",
            systemFileName: "",
            tenantId: 0,
            version: 0,
          },
        ],
        applyId: 0,
        applyInvoice: {
          bankAccount: "",
          bankName: "",
          chargeId: 0,
          enterpriseAddr: "",
          enterpriseTel: "",
          socialCreditCode: "",
          titleContent: "",
          titleType: 0,
          titleTypeName: "",
        },
        applyRemark: "",
        applySeal: {
          applyName: "",
          copyNum: 0,
          itemType: 0,
          itemTypeName: "",
          sealType: 0,
          sealTypeName: "",
        },
        applyType: 0,
        applyTypeName: "",
        auditFileList: [
          {
            changeDate: "",
            changeUser: {
              accountNo: "",
              birthday: "",
              bizTypeGoodat: "",
              changeDate: "",
              changeUser: {
                accountNo: "",
                birthday: "",
                bizTypeGoodat: "",
                changeDate: "",
                changeUser: {},
                changeUserId: 0,
                createDate: "",
                createUser: {},
                createUserId: 0,
                deleteDate: "",
                deleteUserId: 0,
                email: "",
                firstPracticeDate: "",
                headPortraitUrl: "",
                isDelete: 0,
                licenseNo: "",
                locationCityName: "",
                locationProvinceName: "",
                locationTownName: "",
                officeName: "",
                password: "",
                remark: "",
                sex: 0,
                tel: "",
                userId: 0,
                userName: "",
                version: 0,
                wxHeadImg: "",
                wxNickName: "",
                wxOpenId: "",
              },
              changeUserId: 0,
              createDate: "",
              createUser: {},
              createUserId: 0,
              deleteDate: "",
              deleteUserId: 0,
              email: "",
              firstPracticeDate: "",
              headPortraitUrl: "",
              isDelete: 0,
              licenseNo: "",
              locationCityName: "",
              locationProvinceName: "",
              locationTownName: "",
              officeName: "",
              password: "",
              remark: "",
              sex: 0,
              tel: "",
              userId: 0,
              userName: "",
              version: 0,
              wxHeadImg: "",
              wxNickName: "",
              wxOpenId: "",
            },
            changeUserId: 0,
            createDate: "",
            createUser: {},
            createUserId: 0,
            deleteDate: "",
            deleteUserId: 0,
            fileId: 0,
            fileSize: 0,
            isDelete: 0,
            originalFileName: "",
            ossPath: "",
            ossUrl: "",
            remark: "",
            systemFileName: "",
            tenantId: 0,
            version: 0,
          },
        ],
        createUserId: 0,
        createUserName: "",
        invoiceNo: "",
        refuseReason: "",
        relCase: {
          caseId: 0,
          caseName: "",
          caseType: 0,
          caseTypeName: "",
          entrustDate: "",
          subject: "",
          subjectAmount: 0,
        },
        relId: 0,
        relProject: {
          beginDate: "",
          projectId: 0,
          projectName: "",
          projectNo: "",
          type: 0,
          typeName: "",
        },
        relType: 0,
        relTypeName: "",
        reviewUserId: 0,
        reviewUserName: "",
        seqNo: "",
        status: 0,
        statusName: "",
      },
    });
    //返回
    const back = () => {
      router.push("/examine/" + backPath);
    };
    //加载列表数据
    const getList = async () => {
      let res = await getExamineDetail({ applyId });
      console.log(res, "getdetail");
      if (res.code == 200) {
        state.examineDetail = res.data;
      }
    };
    //拒绝
    const handleRefuse = async () => {
      console.log("refuse");
      title.value = "拒绝";
      isShow.value = true;
    };
    //同意
    const handlePass = async () => {
      title.value = "通过";
      remark.value = examineDetail.applyRemark;
      console.log("pass");
      isShow.value = true;
    };
    //关闭弹窗
    const handleCloseDialog = () => {
      // isApplicationShow.value = false;
      remark.value = examineDetail.refuseReason;
      isShow.value = false;
    };
    //取消处理
    const cancelHandle = () => {
      isShow.value = false;
      remark.value = "";
    };
    //确定处理
    const submitHandle = async () => {
      let paramR = {
        applyId,
        refuseReason: remark,
      };
      let paramP = {
        applyId,
        passRemark: remark,
        fileIdList: examineDetail.fileIdList,
        invoiceNo: examineDetail.invoiceNo,
        seqNo: examineDetail.seqNo
      };
      let res =
        title.value == "拒绝"
          ? await refuseExamine(paramR)
          : await passExamine(paramP);
      title.value == "通过";
    };
    onMounted(() => {
      console.log(backPath);

      getList();
    });
    return {
      state,
      btnShow,
      isShow,
      title,
      remark,
      back,
      handleRefuse,
      handlePass,
      handleCloseDialog,
      cancelHandle,
      submitHandle,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/comman.scss";
.examineDetail {
  @include container;
  background: #fff;
  border-radius: 20px;
  .top {
    .topBar {
      padding: 20px 30px;
      border-bottom: 1px solid #eee;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .titleBox {
        display: flex;
        align-items: center;
        .back {
          color: #555;
          display: flex;
          align-items: center;
          &:hover {
            cursor: pointer;
            color: $main-color;
          }
          .iconlfArrow {
            font-size: 20px;
          }
          .text {
            font-size: 14px;
            margin-left: 10px;
          }
        }
        .lineBar {
          width: 1px;
          height: 20px;
          margin: 0 20px;
          border-right: 1px solid #ddd;
        }
        .title {
          display: flex;
          align-items: center;
          .titleSelect {
            margin-left: 20px;
            display: block;
            width: 90px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 30px;
            border: 1px solid #ddd;
            text-decoration: none;
            font-size: 14px;
            color: #555;
          }
          .titleText {
            font-size: 18px;
            color: #333;
            margin: 0 25px;
          }
          .titleApple {
            font-size: 12px;
            color: $main-color;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>