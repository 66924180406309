<template>
  <div class="elLink">
    <div
      class="linkItem"
      v-for="item in linkList"
      :key="item.fileId"
    >
      <!-- @hover="" -->
      <div class="nameLink">
        <i :class="item.fileType =='img'?'el-icon-picture':'el-icon-folder'"></i>
        {{item.fileName}}
      </div>
      <div class="btnLink">
        <el-link>预览</el-link>
        <el-link>下载 </el-link>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Attachment",
  setup(props, context) {},
  props: {
    linkList: {
      type: Array,
      default: () => {
        return [
          {
            icon: "el-icon-edit",
            fileName: "张三入室盗窃案件委托合同.pdf",
            ossurl: "#",
            fileType: "img",
          },
        ];
      },
    },
  },
});
</script>
<style lang='scss' scoped>
.elLink {
  .linkItem {
    display: flex;
    width: 100%;
    .nameLikn {
      //   float: left;
    }
    .btnLink {
      //   float: right;
      margin-left: 100px;
      .el-link {
        margin-left: 5px;
      }
    }
  }
}
</style>