<template>
  <div class="examineMainDetails">
    <div class="leftBox">
      <!-- 案件详情 -->
      <div
        class="itemBox"
        v-if="examineType==2"
      >
        <div class="titleBox">
          <div class="title">案件详情</div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">案件类型</div>
            <div class="valueTitle">{{examineDetail.relCase.caseTypeName}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">委托时间</div>
            <div class="valueTitle">{{examineDetail.relCase.entrustDate}}</div>
          </div>
          <div class="tableBoxCon">
            <div class="tableTitle">我方当事人</div>
            <div class="tableBox">
              <div
                class="tableItem"
                v-for="item in examineDetail.relCase.allies"
                :key="item.holderId"
              >
                <div class="tableItemName">{{item.holderName}}</div>
                <div class="tableItemName">{{item.holderTypeName}}</div>
                <div class="tableItemName">{{item.partyTypeName}}</div>
              </div>
            </div>
          </div>
          <div class="tableBoxCon">
            <div class="tableTitle">对方当事人</div>
            <div class="tableBox">
              <div
                class="tableItem"
                v-for="item in examineDetail.relCase.enemy "
                :key="item.holderId"
              >
                <div class="tableItemName">{{item.holderName}}</div>
                <div class="tableItemName">{{item.holderTypeName}}</div>
                <div class="tableItemName">{{item.partyTypeName}}</div>
              </div>
            </div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">案件标的</div>
            <div class="valueTitle">{{examineDetail.relCase.subject}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">标的金额</div>
            <div class="valueTitle">{{examineDetail.relCase.subjectAmount}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">事实与理由</div>
            <div class="valueTitle">{{examineDetail.relCase.factualReason}}</div>
          </div>
        </div>
      </div>
      <!-- 项目详情 -->
      <div
        class="itemBox"
        v-if="examineType==3"
      >
        <div class="titleBox">
          <div class="title">项目详情</div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">项目类型</div>
            <div class="valueTitle">{{examineDetail.relProject.typeName}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">委托时间</div>
            <div class="valueTitle">{{examineDetail.relProject.beginDate}}</div>
          </div>
          <div
            class="contentItem"
            v-for="item in examineDetail.relProject.tProjectEntrustList"
            :key="item.entrustCustomerId"
          >
            <div class="keyTitle">委托方</div>
            <div class="valueTitle">{{item.entrustCustomerName}}</div>
          </div>
        </div>
      </div>
      <!-- 开票详情 -->
      <div
        class="itemBox"
        v-if="examineType==5"
      >
        <div class="titleBox">
          <div class="title">开票详情</div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">发票类型</div>
            <div class="valueTitle">{{examineDetail.applyInvoice.typeName}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">开票金额</div>
            <div class="valueTitle">{{examineDetail.applyInvoice.charge.chargeName}} {{examineDetail.applyInvoice.charge.amount}}元</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">开票抬头</div>
            <div class="valueTitle"> {{examineDetail.applyInvoice.titleContent}}【{{examineDetail.applyInvoice.titleTypeName}}】</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">税号</div>
            <div class="valueTitle">{{examineDetail.applyInvoice.socialCreditCode}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">开户行</div>
            <div class="valueTitle">{{examineDetail.applyInvoice.bankName}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">单位电话</div>
            <div class="valueTitle">{{examineDetail.applyInvoice.enterpriseTel}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">单位地址</div>
            <div class="valueTitle">{{examineDetail.applyInvoice.enterpriseAddr}}</div>
          </div>
        </div>
      </div>
      <!-- 用印申请详情 -->
      <div
        class="itemBox"
        v-if="examineType==1"
      >
        <div class="titleBox">
          <div class="title">申请详情</div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">用印事项</div>
            <div class="valueTitle">{{examineDetail.applySeal.itemTypeName}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">关联案件</div>
            <div class="valueTitle">{{examineDetail.relCase.caseName}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">印章类型</div>
            <div class="valueTitle">{{examineDetail.applySeal.sealTypeName}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">用印份数</div>
            <div class="valueTitle">{{examineDetail.applySeal.copyNum}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">申请人</div>
            <div class="valueTitle">{{examineDetail.createUserName}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">审批人</div>
            <div class="valueTitle">{{examineDetail.reviewUserName}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle textRight">备注</div>
            <div class="valueTitle flex1">{{examineDetail.applyRemark}}</div>
          </div>
        </div>
      </div>
      <!-- 收款申请详情 -->
      <div
        class="itemBox"
        v-if="examineType==4"
      >
        <div class="titleBox">
          <div class="title">申请详情</div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">款项金额</div>
            <div class="valueTitle">{{examineDetail.applyInvoice.charge.chargeName}} {{examineDetail.applyInvoice.charge.amount}}元</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">申请人</div>
            <div class="valueTitle">{{examineDetail.createUserName}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">审批人</div>
            <div class="valueTitle">{{examineDetail.reviewUserName}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle textRight">备注</div>
            <div class="valueTitle flex1">{{examineDetail.applyRemark}}</div>
          </div>
        </div>
      </div>
      <!-- 申请详情 -->
      <div
        class="itemBox"
        v-if="examineType!=1&&examineType!=4"
      >
        <div class="titleBox">
          <div class="title">申请详情</div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">申请人</div>
            <div class="valueTitle">{{examineDetail.createUserName}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">审批人</div>
            <div class="valueTitle">{{examineDetail.reviewUserName}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle textRight">备注</div>
            <div class="valueTitle flex1">{{examineDetail.applyRemark}}</div>
          </div>
        </div>
      </div>
      <!-- 附件 -->
      <div
        class="itemBox"
        v-if="examineType!=3"
      >
        <div class="titleBox">
          <div class="title">附件</div>
        </div>
        <div class="contentBox">
          <attachment :linkList="examineDetail.fileIdList"></attachment>
        </div>
      </div>
    </div>
    <div class="rightBox">
      <!-- 审批信息 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">当事人信息</div>
        </div>
        <div class="contentBox">
          <!-- <div style="height: 120px;"> -->
          <div class="block">
            <el-timeline>
              <el-timeline-item
                :timestamp="examineDetail.createDate"
                placement="top"
                size='large'
                color="#ff9e33"
              >
                <h4>提交申请</h4>
                <p>{{examineDetail.createUserName}}</p>
              </el-timeline-item>
              <el-timeline-item
                :timestamp="examineDetail.changeDate"
                placement="top"
                size='large'
                :color="examineDetail.status!=1?'#ff9e33':''"
              >
                <span :class="judgeStatus(examineDetail.status)">{{examineDetail.statusName}}</span>
                <p>{{examineDetail.reviewUserName}}</p>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
      <!-- 审批意见 -->
      <div
        class="itemBox itemBox2"
        v-if="examineType!=1"
      >
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">审批意见</div>
            <div class="valueTitle">{{examineDetail.passRemark}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">银行流水号</div>
            <div class="valueTitle">{{examineDetail.seqNo}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">发票编号</div>
            <div class="valueTitle">{{examineDetail.invoiceNo}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">附件</div>
            <div class="valueTitle">附件图1、2</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, reactive } from "vue";
import attachment from "@/components/Attachment.vue";
export default defineComponent({
  components: { attachment },
  name: "ExamineDetailContent",
  setup(props) {
    const examine_Type = reactive({
      //用印
      examine_Type_Show_1: false,
      //立案
      examine_Type_Show_2: false,
      //立项
      examine_Type_Show_3: false,
      //收款
      examine_Type_Show_4: false,
      //开票
      examine_Type_Show_5: false,
    });
    const state = reactive({});
    //状态列样式
    const judgeStatus = (item) => {
      return item == 2 ? "pass" : item == 1 ? "" : "refuse";
    };
    onMounted(() => {
      console.log("examineType", props.examineType);
      props.examineType &&
        (examine_Type["examine_Type_Show_" + props.examineType] = true);
    });
    return {
      state,
      examine_Type,
      judgeStatus,
    };
  },
  props: {
    examineType: {
      type: String,
    },
    examineDetail: {
      type: Object,
      require: true,
      default: () => {
        return {};
      },
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/comman.scss";
.examineMainDetails {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;
  .leftBox {
    border-right: 1px solid #eee;
  }
  .leftBox,
  .rightBox {
    width: 50%;
    box-sizing: border-box;
    .itemBox {
      border-bottom: 1px solid #eee;
      padding: 30px 0;
      margin: 0 20px;
      &:last-child {
        border: none;
      }
      .titleBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        .title {
          font-size: 16px;
          color: #666666;
        }
      }
      .contentBox {
        padding-left: 10px;
        .contentItem {
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          .keyTitle {
            min-width: 56px;
            width: 88px;
            text-align: right;
            margin-right: 20px;
          }
          .textRight {
            text-align: right;
          }
          .flex1 {
            flex: 1;
            line-height: 20px;
          }
          .keyTitle,
          .valueTitle {
            font-size: 14px;
            color: #777777;
          }
          .keyIcon {
            font-size: 40px;
            color: $main-color;
            margin-right: 30px;
          }
        }
        .tableBoxCon {
          width: 90%;
          display: flex;
          margin: auto;
          margin-bottom: 20px;
          .tableTitle {
            font-size: 16px;
            color: #666;
            width: 60px;
          }
          .tableBox {
            width: 100%;
            .tableItem {
              display: flex;
              width: 100%;
              margin-bottom: 20px;
              &:last-child {
                margin-bottom: 0;
              }
              .tableItemName {
                // width:calc( (100% - 60px) / 2 );
                width: 165px;
                font-size: 14px;
                color: #777;
                &:nth-child(2) {
                  width: 100px;
                }
                &:nth-child(3) {
                  width: 150px;
                }
              }
            }
          }
        }
      }
    }
    .itemBox2 {
      background-color: rgba(220, 220, 220, 0.109);
      margin-top: 20px;
      border-radius: 10px;
    }
  }
  .rightBox {
    padding-left: 20px;
    .contentBox {
      .tableBoxCon {
        width: 90%;
        display: flex;
        margin: auto;
        margin-bottom: 20px;
        .tableTitle {
          font-size: 16px;
          color: #666;
          width: 60px;
        }
        .tableBox {
          width: 100%;
          .tableItem {
            display: flex;
            width: 100%;
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0;
            }
            .tableItemName {
              // width:calc( (100% - 60px) / 2 );
              width: 165px;
              font-size: 14px;
              color: #777;
              &:nth-child(2) {
                width: 100px;
              }
              &:nth-child(3) {
                width: 150px;
              }
            }
          }
        }
      }
    }
  }
}
.pass {
  color: chartreuse;
}
.refuse {
  color: red;
}
</style>