import myserver from "./myHttp";
// 获取审批列表
export const getExamineList = (params) => {
  return myserver.myHttp({
    url: "/tApply/page",
    params,
  });
};
// 新建
export const deleteExamine = (data) => {
  return myserver.myHttp({
    url: "/tApply/save",
    method: "POST",
    data,
  });
};
// 删除
export const addExamine = (data) => {
  return myserver.myHttp({
    url: "/tApply/delete",
    method: "POST",
    data,
  });
};
//同意
export const passExamine = (data) => {
  return myserver.myHttp({
    url: "/tApply/pass",
    method: "POST",
    data,
  });
};
//拒绝
export const refuseExamine = (data) => {
  return myserver.myHttp({
    url: "/tApply/refuse",
    method: "POST",
    data,
  });
};
// 基本信息详情
export const getExamineDetail = (params) => {
  return myserver.myHttp({
    url: "/tApply/detail",
    params,
  });
};

// 获取关联案件列表
export const getCaseList = (params) => {
  return myserver.myHttp({
    url: "/tCase/Page",
    params,
  });
};
// 获取关联项目列表
export const getProjectList = (params) => {
  return myserver.myHttp({
    url: "/tProject/projectPage",
    params,
  });
};
// 获取审批人列表
export const getReviewUserList = (params) => {
  return myserver.myHttp({
    url: "tLawOfficeReviewer/getByType",
    params,
    headers: {
      "SELECT-TENANT-ID": "OFFICE",
    },
  });
};
// 获取案件关联收入列表
export const getCaseRealCharge = (params) => {
  return myserver.myHttp({
    url: "/tCase/getRealChargePage",
    params,
  });
};
// 获取项目关联收入列表
export const getProjectRealCharge = (params) => {
  return myserver.myHttp({
    url: "/tProject/getRealChargePage",
    params,
  });
};
export const uploadFile = (data) => {
  return myserver.myHttp({
    url: "/tFile/uploadAttachment",
    method: "POST",
    data,
  });
};

export const getProjectInfo = (params) => {
  return myserver.myHttp({
    url: "/tProject/getProjectInfo/" + params.projectId,
    method: "get",
    params,
  });
};
